var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { attrs: { align: "center" } }, [
      _c("h4", { staticClass: "q-mb-sm" }, [_vm._v("Definisci Quietanza")]),
      _c("h5", { staticClass: "q-mt-sm q-mb-sm" }, [
        _vm._v("Pratica N. " + _vm._s(_vm.RecordSelezionato.numero_interno)),
      ]),
      _c("br"),
      _c("hr"),
    ]),
    _c("div", { staticClass: "row justify-center" }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-5 justify-center" },
        [
          _c("h5", [_vm._v("Allega PDF Titolo di quietanza")]),
          _c("q-uploader", {
            ref: "FilesAllegati",
            staticStyle: {
              width: "100%",
              "min-height": "350px",
              "box-shadow":
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            },
            attrs: {
              url: "https://localhost:1000",
              color: "teal",
              label: "Trascina QUI i documenti di questa Quietanza",
              multiple: "",
              name: "upload_quietanza",
              "hide-upload-btn": "",
              filter: _vm.checkFileType,
            },
            on: {
              rejected: function ($event) {
                return _vm.onRejected()
              },
              removed: _vm.onRimuoviFileAllegati,
              added: _vm.onAggiungiFileAllegati,
            },
          }),
          _c("br"),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12 col-md-5 q-px-md" },
        [
          _c(
            "q-list",
            { attrs: { separator: "" } },
            [
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Premio")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _c("h4", [
                          _vm._v(
                            _vm._s(_vm.quietanza_da_definire.premio) + " Euro"
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Contraente")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(
                          _vm._s(_vm.RecordSelezionato.name.toUpperCase())
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Tariffa")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.quietanza_da_definire.prodotto_id.toUpperCase()
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Compagnia")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quietanza_da_definire.compagnia)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Fornitore")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(_vm._s(_vm.quietanza_da_definire.fornitore)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Decorrenza quietanza")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.TranscodificaData(
                              _vm.quietanza_da_definire.decorrenza
                            )
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-item-section",
                    [
                      _c("q-item-label", [_vm._v("Scadenza quietanza")]),
                      _c("q-item-label", { attrs: { caption: "" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.TranscodificaData(
                              _vm.quietanza_da_definire.scadenza
                            )
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("br"),
          _c("br"),
          _c(
            "div",
            { staticClass: "col-12 col-md-5 q-px-md" },
            [
              _c("h5", [_vm._v("Dati del Titolo di quietanza")]),
              _c("q-input", {
                attrs: {
                  outlined: "",
                  dense: "",
                  mask: "##/##/####",
                  name: "decorrenza",
                  label: "Data di decorrenza quietanza",
                  rules: [
                    function (val) {
                      return !_vm.isNotValid(val) || "Data di decorrenza errata"
                    },
                  ],
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [
                        _c(
                          "q-icon",
                          {
                            staticClass: "cursor-pointer",
                            attrs: { name: "event" },
                          },
                          [
                            _c(
                              "q-popup-proxy",
                              {
                                ref: "qDateProxy",
                                attrs: {
                                  "transition-show": "scale",
                                  "transition-hide": "scale",
                                },
                              },
                              [
                                _c(
                                  "q-date",
                                  {
                                    attrs: { mask: "DD/MM/YYYY" },
                                    model: {
                                      value: _vm.dati_modificati.decorrenza,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dati_modificati,
                                          "decorrenza",
                                          $$v
                                        )
                                      },
                                      expression: "dati_modificati.decorrenza",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "row items-center justify-end",
                                      },
                                      [
                                        _c("q-btn", {
                                          directives: [
                                            {
                                              name: "close-popup",
                                              rawName: "v-close-popup",
                                            },
                                          ],
                                          attrs: { label: "Close", flat: "" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.dati_modificati.decorrenza,
                  callback: function ($$v) {
                    _vm.$set(_vm.dati_modificati, "decorrenza", $$v)
                  },
                  expression: "dati_modificati.decorrenza",
                },
              }),
              _c("q-input", {
                attrs: {
                  outlined: "",
                  dense: "",
                  mask: "##/##/####",
                  name: "scadenza",
                  label: "Data di scadenza quietanza",
                  rules: [
                    function (val) {
                      return !_vm.isNotValid(val) || "Data di scadenza errata"
                    },
                  ],
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [
                        _c(
                          "q-icon",
                          {
                            staticClass: "cursor-pointer",
                            attrs: { name: "event" },
                          },
                          [
                            _c(
                              "q-popup-proxy",
                              {
                                ref: "qDateProxy",
                                attrs: {
                                  "transition-show": "scale",
                                  "transition-hide": "scale",
                                },
                              },
                              [
                                _c(
                                  "q-date",
                                  {
                                    attrs: { mask: "DD/MM/YYYY" },
                                    model: {
                                      value: _vm.dati_modificati.scadenza,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dati_modificati,
                                          "scadenza",
                                          $$v
                                        )
                                      },
                                      expression: "dati_modificati.scadenza",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "row items-center justify-end",
                                      },
                                      [
                                        _c("q-btn", {
                                          directives: [
                                            {
                                              name: "close-popup",
                                              rawName: "v-close-popup",
                                            },
                                          ],
                                          attrs: { label: "Close", flat: "" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.dati_modificati.scadenza,
                  callback: function ($$v) {
                    _vm.$set(_vm.dati_modificati, "scadenza", $$v)
                  },
                  expression: "dati_modificati.scadenza",
                },
              }),
              _c("q-input", {
                attrs: {
                  outlined: "",
                  dense: "",
                  mask: "##/##/####",
                  name: "data_incasso",
                  rules: [
                    function (val) {
                      return !_vm.isNotValid(val) || "Data di Incasso errata"
                    },
                  ],
                  label: "Data di incasso quietanza",
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [
                        _c(
                          "q-icon",
                          {
                            staticClass: "cursor-pointer",
                            attrs: { name: "event" },
                          },
                          [
                            _c(
                              "q-popup-proxy",
                              {
                                ref: "qDateProxy",
                                attrs: {
                                  "transition-show": "scale",
                                  "transition-hide": "scale",
                                },
                              },
                              [
                                _c(
                                  "q-date",
                                  {
                                    attrs: { mask: "DD/MM/YYYY" },
                                    model: {
                                      value: _vm.dati_modificati.data_incasso,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dati_modificati,
                                          "data_incasso",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dati_modificati.data_incasso",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "row items-center justify-end",
                                      },
                                      [
                                        _c("q-btn", {
                                          directives: [
                                            {
                                              name: "close-popup",
                                              rawName: "v-close-popup",
                                            },
                                          ],
                                          attrs: { label: "Close", flat: "" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.dati_modificati.data_incasso,
                  callback: function ($$v) {
                    _vm.$set(_vm.dati_modificati, "data_incasso", $$v)
                  },
                  expression: "dati_modificati.data_incasso",
                },
              }),
              _c("q-select", {
                attrs: {
                  outlined: "",
                  dense: "",
                  "error-message":
                    "Devi definire se la quietanza richiede il Simplo oppure no",
                  options: _vm.elenco_dati_simplo,
                  label: "ABILITA RCHIESTA SIMPLO",
                  name: "abilita_richiesta_simplo",
                  rules: [
                    function (val) {
                      return (
                        val !== "" ||
                        "Devi definire se la quietanza richiede un Simplo oppure no"
                      )
                    },
                  ],
                  "emit-value": "",
                  "map-options": "",
                  "virtual-scroll-slice-size": "0",
                  "virtual-scroll-item-size": "0",
                },
                model: {
                  value: _vm.dati_modificati.abilita_simplo,
                  callback: function ($$v) {
                    _vm.$set(_vm.dati_modificati, "abilita_simplo", $$v)
                  },
                  expression: "dati_modificati.abilita_simplo",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "col-12 col-md-12 justify-center" }, [
        _c("br"),
        _c("hr"),
        _c("div", { staticClass: "col-12 col-md-10 row" }, [
          _c(
            "div",
            { staticClass: "col-12 col-md-6", attrs: { align: "center" } },
            [
              _c("QQButton", {
                attrs: {
                  label: "INDIETRO",
                  color: "blue-grey",
                  icon: "undo",
                  size: "md",
                  icon_align: "left",
                  disable: _vm.pulsante_disattivato,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onIndietroClicked()
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12 col-md-6", attrs: { align: "center" } },
            [
              _c("QQButton", {
                attrs: {
                  label: "Registra",
                  color: "blue-grey",
                  icon: "mdi-content-save-edit-outline",
                  size: "md",
                  icon_align: "right",
                  disable: _vm.pulsante_disattivato,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onRegistraClicked()
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }