import { render, staticRenderFns } from "./DefinisciQuietanza.vue?vue&type=template&id=f1d6d2fe&"
import script from "./DefinisciQuietanza.vue?vue&type=script&lang=js&"
export * from "./DefinisciQuietanza.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


import QUploader from 'quasar/src/components/uploader/QUploader.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSelect from 'quasar/src/components/select/QSelect.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QUploader,QList,QItem,QItemSection,QItemLabel,QInput,QIcon,QPopupProxy,QDate,QBtn,QSelect});qInstall(component, 'directives', {ClosePopup});
/* hot reload */
if (module.hot) {
  var api = require("/builds/navert/abynext-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f1d6d2fe')) {
      api.createRecord('f1d6d2fe', component.options)
    } else {
      api.reload('f1d6d2fe', component.options)
    }
    module.hot.accept("./DefinisciQuietanza.vue?vue&type=template&id=f1d6d2fe&", function () {
      api.rerender('f1d6d2fe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/PiattaformaRami/Pratiche/Rinnovi/DefinisciQuietanza.vue"
export default component.exports